<template>
    <LoadingWrapper
        :isLoading="!isOpen || isFetching"
        class="px-4 py-4"
    >
        <FormKit
            id="select-profile-form"
            type="form"
            v-model="dirtyData"
            :actions="false"
            :config="{ validationVisibility: 'dirty' }"
            #default="context"
        >
            <Panel
                id="select-profile-wrapper"
                class="mb-5"
            >
                <template #header>
                    <div class="flex flex-row justify-between">
                        <h3
                            class="inline-flex flex-grow align-middle items-center text-xl text-primary-700 col-span-3"
                        >
                            Map Dataset Fields
                        </h3>
                        <div
                            v-if="isEditing"
                            class="inline-flex align-middle text-right text-xl text-gray-700"
                        >
                            <PencilAltIcon
                                class="h-5 w-5 mt-1 mr-2"
                            ></PencilAltIcon>
                            <div>Editing</div>
                        </div>
                        <div
                            v-else
                            class="inline-flex align-middle text-right text-xl text-gray-700"
                        >
                            <SearchIcon class="h-5 w-5 mt-1 mr-2"></SearchIcon>
                            <div>Viewing</div>
                        </div>
                    </div>
                </template>
                <template #default>
                    <!-- EDIT BUTTON -->
                    <div class="float-right">
                        <transition
                            enter-active-class="transition ease-out duration-150"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-100"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <ModalButton
                                v-if="isViewing"
                                id="edit-button"
                                theme="primary"
                                :disabled="isEditing"
                                @click.prevent="startEdit"
                            >
                                <PencilAltIcon
                                    class="h-5 w-5 mr-2"
                                ></PencilAltIcon>
                                Edit
                            </ModalButton>
                        </transition>
                    </div>
                    <!-- PRESET SELECTOR -->
                    <FormSection
                        id="profile-selector"
                        class="pt-4 hidden w-full"
                        title="Preset"
                        description="Select a preset from the list below."
                        :grid="['grid', 'grid-cols-1', 'pt-4', 'gap-y-4']"
                    >
                        <FormKit
                            id="preset"
                            type="select"
                            name="preset"
                            @input="onPresetChanged"
                            :options="presetIndexOptions"
                            :disabled="isViewing"
                            :classes="{
                                outer: '$reset outer px-0 sm:px-4',
                                wrapper:
                                    '$reset wrapper disabled:cursor-not-allowed',
                                label: '$reset label font-bold text-sm',
                                inner: '$reset inner w-full',
                                input: '$reset input w-full rounded-lg px-3 disabled:bg-gray-50 disabled:cursor-not-allowed',
                            }"
                        />
                        <transition
                            enter-active-class="transition ease-out duration-150"
                            enter-from-class="transform opacity-0 -translate-y-10"
                            enter-to-class="transform opacity-100 translate-y-0"
                            leave-active-class="transition ease-in duration-100"
                            leave-from-class="transform opacity-100 translate-y-0"
                            leave-to-class="transform opacity-0 -translate-y-10"
                        >
                            <div v-if="isNewPresetSelected">
                                <FormKit
                                    id="name"
                                    type="text"
                                    name="name"
                                    :placeholder="
                                        isViewing
                                            ? 'Untitled Preset'
                                            : 'Your name for the new preset.'
                                    "
                                    :help="
                                        isViewing
                                            ? ''
                                            : 'Provide a useful name for the mapping profile.'
                                    "
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        wrapper:
                                            '$reset wrapper disabled:cursor-not-allowed',
                                        label: '$reset label font-bold text-sm',
                                        inner: isViewing
                                            ? '$reset inner w-full border-none text-gray-600'
                                            : '$reset inner w-full',
                                        input: '$reset input w-full rounded-lg px-3 disabled:bg-gray-50 disabled:cursor-not-allowed',
                                    }"
                                />
                            </div>
                        </transition>
                    </FormSection>
                    <!-- MAPPING PROFILE > RULES -->
                    <FormSection
                        id="profile-rules"
                        class="pt-4"
                        title="Mapping"
                        description="Please review the Preview table and confirm the mapped fields below match the specified CSV file format."
                        :grid="[
                            'grid',
                            'grid-cols-1',
                            'pt-4',
                            'sm:grid-cols-2',
                            'gap-y-4',
                            'sm:gap-x-2',
                        ]"
                    >
                        <FormKit
                            id="settings-section-1"
                            name="settings"
                            type="group"
                        >
                            <!-- SETTINGS > HEADERS -->
                            <FormKit
                                id="settings-headers-clean"
                                type="checkbox"
                                name="headers"
                                label="Includes Field Headers?"
                                :help="
                                    isHeadersIncluded
                                        ? 'First row of data contains the field headers.'
                                        : 'First row of data contains raw values.'
                                "
                                v-model="dirtyData.settings.headers"
                                @input="onOffsetFieldChanged"
                                :disabled="isViewing"
                                :classes="{
                                    outer: '$reset outer px-2 sm:px-2 sm:ml-12 md:ml-48',
                                    wrapper:
                                        '$reset wrapper flex flex-col-reverse disabled:cursor-not-allowed',
                                    label: '$reset label font-bold text-sm',
                                    inner: '$reset inner flex-none h-6',
                                    help: 'mt-1',
                                    input: isEditing
                                        ? '$reset checkbox checked block h-6 my-2 text-primary-600 focus:ring-primary-500 border-gray-900 rounded group-hover:cursor-pointer'
                                        : '$reset checkbox checked block h-6 my-2 text-gray-600 border-gray-300 rounded group-hover:cursor-not-allowed',
                                }"
                                :ignore="true"
                            >
                                <template #input="context">
                                    <Switch
                                        id="includeHeadersSwitch"
                                        v-if="!!context"
                                        :disabled="isViewing"
                                        :model-value="
                                            dirtyData.settings.headers
                                        "
                                        @update:model-value="
                                            toggleHeaders(context)
                                        "
                                        :class="[
                                            isHeadersIncluded
                                                ? 'bg-primary-500'
                                                : 'bg-gray-100',
                                            isEditing
                                                ? 'hover:cursor-pointer'
                                                : 'disabled:cursor-not-allowed',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none',
                                        ]"
                                        value="on"
                                    >
                                        <span class="sr-only">Use setting</span>
                                        <span
                                            :class="[
                                                isHeadersIncluded
                                                    ? 'translate-x-5'
                                                    : 'translate-x-0',
                                                'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                                            ]"
                                        >
                                            <span
                                                :class="[
                                                    isHeadersIncluded
                                                        ? 'opacity-0 ease-out duration-100'
                                                        : 'opacity-100 ease-in duration-200',
                                                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                                                ]"
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    class="h-3 w-3 text-gray-300"
                                                    fill="none"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path
                                                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                        stroke="currentColor"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <span
                                                :class="[
                                                    isHeadersIncluded
                                                        ? 'opacity-100 ease-in duration-200'
                                                        : 'opacity-0 ease-out duration-100',
                                                    'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                                                ]"
                                                aria-hidden="true"
                                            >
                                                <svg
                                                    class="h-3 w-3 text-indigo-500"
                                                    fill="currentColor"
                                                    viewBox="0 0 12 12"
                                                >
                                                    <path
                                                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                                    />
                                                </svg>
                                            </span>
                                        </span>
                                    </Switch>
                                </template>
                            </FormKit>
                            <!-- SETTINGS > OFFSET -->
                            <FormKit
                                id="settings-offset"
                                type="number"
                                name="offset"
                                label="Starting Line Number:"
                                step="1"
                                min="1"
                                value="1"
                                @input="onOffsetFieldChanged"
                                :disabled="isViewing"
                                :classes="{
                                    outer: '$reset outer px-0 sm:px-4',
                                    wrapper:
                                        '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                    label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                    inner: '$reset inner col-span-2',
                                    input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                }"
                            />
                        </FormKit>
                        <FormKit
                            id="rules-section"
                            name="rules"
                            type="list"
                        >
                            <!-- RULES > DATE -->
                            <FormKit
                                id="rules-date"
                                name="date"
                                type="group"
                            >
                                <FormKit
                                    id="rules-date-index"
                                    type="select"
                                    name="index"
                                    label="Date:"
                                    :options="
                                        getFieldIndexOptions(
                                            'date',
                                            isHeadersIncluded,
                                            dirtyData.settings.offset - 1
                                        )
                                    "
                                    validation="required"
                                    validation-label="Date"
                                    @input="onDateTimeFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-date-format"
                                    ref="dateFormatInput"
                                    type="select"
                                    name="format"
                                    label="Date Format:"
                                    placeholder="Select Date Format"
                                    :options="getFieldFormatOptions('date')"
                                    validation="required|dateformat"
                                    :validation-rules="{
                                        dateformat: dateFormatValidation,
                                    }"
                                    validation-label="Date format"
                                    @input="onFieldChanged"
                                    :disabled="
                                        isViewing ||
                                        dirtyData.rules[0].index === null
                                    "
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-date-target"
                                    name="target"
                                    type="hidden"
                                    value="date"
                                />
                            </FormKit>
                            <!-- RULES > TIME -->
                            <FormKit
                                id="rules-time"
                                name="time"
                                type="group"
                            >
                                <FormKit
                                    id="rules-time-index"
                                    type="select"
                                    name="index"
                                    label="Time:"
                                    :options="
                                        getFieldIndexOptions(
                                            'time',
                                            isHeadersIncluded,
                                            dirtyData.settings.offset - 1
                                        )
                                    "
                                    validation="required"
                                    validation-label="Time"
                                    @input="onDateTimeFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-time-format"
                                    ref="timeFormatInput"
                                    type="select"
                                    name="format"
                                    label="Time Format:"
                                    placeholder="Select Time Format"
                                    validation="required|timeformat"
                                    :validation-rules="{
                                        timeformat: timeFormatValidation,
                                    }"
                                    validation-label="Time format"
                                    :options="getFieldFormatOptions('time')"
                                    @input="onFieldChanged"
                                    :disabled="
                                        isViewing ||
                                        dirtyData.rules[1].index === null
                                    "
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-time-target"
                                    name="target"
                                    type="hidden"
                                    value="time"
                                />
                            </FormKit>
                            <!-- RULES > TEMPERATURE -->
                            <FormKit
                                id="rules-temp"
                                name="temp"
                                type="group"
                            >
                                <FormKit
                                    id="rules-temp-index"
                                    type="select"
                                    name="index"
                                    label="Temperature:"
                                    :options="
                                        getFieldIndexOptions(
                                            'temp',
                                            isHeadersIncluded,
                                            dirtyData.settings.offset - 1
                                        )
                                    "
                                    validation="required"
                                    validation-label="Temperature"
                                    @input="onDecimalFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-temp-format"
                                    type="select"
                                    name="format"
                                    label="Temperature Scale:"
                                    placeholder="Select Temperature Scale"
                                    :options="getFieldFormatOptions('temp')"
                                    validation="required"
                                    validation-label="Temperature scale"
                                    @input="onFieldChanged"
                                    :disabled="
                                        isViewing ||
                                        dirtyData.rules[2].index === null
                                    "
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-center',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-temp-target"
                                    name="target"
                                    type="hidden"
                                    value="temp"
                                />
                            </FormKit>
                            <!-- RULES > REL. HUM. -->
                            <FormKit
                                id="rules-rh"
                                name="rh"
                                type="group"
                            >
                                <FormKit
                                    id="rules-rh-index"
                                    type="select"
                                    name="index"
                                    label="Relative Humidity:"
                                    :options="
                                        getFieldIndexOptions(
                                            'rh',
                                            isHeadersIncluded,
                                            dirtyData.settings.offset - 1
                                        )
                                    "
                                    validation="required"
                                    validation-label="Relative humidity"
                                    @input="onDecimalFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full sm:w-20 font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-end',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                                <FormKit
                                    id="rules-rh-target"
                                    name="target"
                                    type="hidden"
                                    value="rh"
                                />
                            </FormKit>
                            <!-- RULES > TIME ZONE -->
                            <FormKit
                                id="settings-timezone"
                                name="timezone"
                                type="group"
                                :ignore="true"
                            >
                                <FormKit
                                    id="settings-timezone-index"
                                    type="select"
                                    name="index"
                                    label="Time Zone:"
                                    :options="getTimeZoneOptions()"
                                    validation="required"
                                    validation-label="Time zone"
                                    @input="onFieldChanged"
                                    v-model="dirtyData.settings.timezone"
                                    :disabled="isViewing"
                                    :ignore="true"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper grid grid-cols-1 sm:grid-cols-3 sm:space-y-0 gap-x-2',
                                        label: '$reset label col-span-1 gap-y-2 sm:gap-y-0 w-full sm:w-20 font-bold text-sm text-justify sm:text-right place-self-start sm:place-self-end',
                                        inner: '$reset inner col-span-2',
                                        input: '$reset w-full px-3 rounded-lg border-1 text-black border-black hover:border-primary-500 disabled:border-gray-400 disabled:cursor-not-allowed disabled:text-gray-700',
                                    }"
                                />
                            </FormKit>
                        </FormKit>
                    </FormSection>
                    <!-- MAPPING PROFILE > SETTINGS -->
                    <BasicAccordion title="Settings">
                        <div class="ml-2 text-sm text-gray-500">
                            Please check to make sure the following parser
                            settings are correct for reading your CSV file.
                        </div>
                        <!-- MAPPING PROFILE > SETTINGS -->
                        <FormSection
                            id="profile-settings"
                            class="pt-4"
                            :grid="[
                                'grid',
                                'grid-cols-1',
                                'sm:grid-cols-2',
                                'gap-y-4',
                                'sm:gap-x-2',
                            ]"
                        >
                            <FormKit
                                id="settings-section"
                                name="settings"
                                type="group"
                            >
                                <!-- SETTINGS > DELIMITER -->
                                <FormKit
                                    id="settings-delimiter"
                                    type="select"
                                    name="delimiter"
                                    label="Delimiter"
                                    placeholder="No Delimiter Selected"
                                    :options="[
                                        { label: `Comma (,)`, value: `,` },
                                        { label: `Tab (\\t)`, value: `\\t` },
                                    ]"
                                    validation="required"
                                    @input="onFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper disabled:cursor-not-allowed',
                                        label: '$reset label font-bold text-sm',
                                        inner: '$reset inner w-full',
                                        input: '$reset input w-full rounded-lg px-3 disabled:bg-gray-50 disabled:cursor-not-allowed',
                                    }"
                                />
                                <!-- SETTINGS > DECIMAL -->
                                <FormKit
                                    id="settings-decimal"
                                    ref="decimalInput"
                                    type="select"
                                    name="decimal"
                                    label="Decimal Separator"
                                    placeholder="No Separator Selected"
                                    :options="[
                                        { label: `Point (.)`, value: `.` },
                                        { label: `Comma (,)`, value: `,` },
                                    ]"
                                    validation="required|decimal"
                                    :validation-rules="{
                                        decimal: decimalValidation,
                                    }"
                                    @input="onFieldChanged"
                                    :disabled="isViewing"
                                    :classes="{
                                        outer: '$reset outer px-0 sm:px-4',
                                        message:
                                            '$reset text-red-500 text-right mb-1 text-xs',
                                        wrapper:
                                            '$reset wrapper disabled:cursor-not-allowed',
                                        label: '$reset label font-bold text-sm',
                                        inner: '$reset inner w-full',
                                        input: '$reset input w-full rounded-lg px-3 disabled:bg-gray-50 disabled:cursor-not-allowed',
                                    }"
                                />
                            </FormKit>
                        </FormSection>
                    </BasicAccordion>
                    <hr class="mt-6" />
                    <!-- MAPPING PROFILE > PREVIEW -->
                    <FormSection
                        class="pt-4"
                        id="profile-preview"
                        title="Preview"
                        description="The following are sample values for the specified CSV file."
                        :grid="['grid', 'grid-cols-1', 'pt-4']"
                    >
                        <div
                            class="grid grid-cols-1 mb-2 sm:grid-cols-2 sm:gap-x-2 text-sm text-gray-600 break-all"
                        >
                            <div>
                                <span class="font-bold">File:</span>
                                {{ targetFilename }}
                            </div>
                            <div>
                                <span class="font-bold">Location:</span>
                                {{ targetLocationDisplayName }}
                            </div>
                        </div>
                        <LoadingWrapper :isLoading="false">
                            <AgGridVue
                                class="ag-theme-alpine w-full block"
                                style="min-height: 250px"
                                :domLayout="grid.state.domLayout.value"
                                :defaultColDef="grid.state.defaultColDef.value"
                                :columnDefs="grid.properties.columnDefs.value"
                                :rowSelection="grid.state.rowSelection.value"
                                :rowHeight="grid.state.rowHeight.value"
                                :rowData="grid.properties.clampedRowData.value"
                                @grid-ready="grid.actions.onGridReady"
                                @column-resized="grid.actions.onColumnResized"
                                @selection-changed="
                                    grid.actions.onSelectionChanged
                                "
                            />
                        </LoadingWrapper>
                    </FormSection>
                </template>
                <template #footer>
                    <div class="flex flex-row justify-between">
                        <div class="flex-grow flex flex-col space-y-2">
                            <div class="text-sm text-gray-600">* Required</div>
                        </div>
                        <div>
                            <div class="flex justify-end space-x-4">
                                <transition-group
                                    enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95 translate-x-6"
                                    enter-to-class="transform opacity-100 scale-100 translate-x-0"
                                    leave-active-class="absolute transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100 translate-x-0"
                                    leave-to-class="transform opacity-0 scale-95 -translate-x-6"
                                >
                                    <ModalButton
                                        v-if="isViewing"
                                        id="close-button"
                                        key="close-button"
                                        theme="white"
                                        :label="'Close'"
                                        :disabled="isEditing"
                                        @click.prevent="closeModal"
                                    />
                                    <ModalButton
                                        v-if="isEditing"
                                        id="cancel-button"
                                        key="cancel-button"
                                        theme="white"
                                        :label="'Cancel'"
                                        :disabled="isViewing"
                                        @click.prevent="cancelEdit"
                                    />
                                    <!-- <ModalButton
                                        v-if="isEditing"
                                        id="debug-submit-button"
                                        key="submit-button"
                                        theme="primary"
                                        label="Save"
                                        :disabled="isViewing && context"
                                        @click.prevent="debugSaveProfile"
                                    /> -->
                                    <ModalButton
                                        v-if="isEditing"
                                        id="submit-button"
                                        key="submit-button"
                                        theme="primary"
                                        label="Save"
                                        :disabled="
                                            isViewing || !context.state.valid
                                        "
                                        @click.prevent="saveProfile"
                                    />
                                </transition-group>
                            </div>
                        </div>
                    </div>
                </template>
            </Panel>
        </FormKit>
        <DebugFrame
            v-if="selector.state.open.value"
            :key="mountKey(`profile-modal-debug`)"
            id="generic"
            :startHidden="frame.startHidden"
            :debug="frame.isEnabled"
            :data="frame.data"
        />
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        toRef,
        onMounted,
        onUnmounted,
        ref,
        watch,
    } from 'vue';
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
    import { MappingProfile } from '@/models/v1/mappings/MappingProfile';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import { Switch } from '@headlessui/vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import { PencilAltIcon, SearchIcon } from '@heroicons/vue/outline';
    import BasicAccordion from '@/components/BasicAccordion.vue';

    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useMounted } from '@vueuse/core';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';
    import { useSelectProfileForm } from '~CSVUploader/hooks/form/useSelectProfileForm';
    import { useCSVPreviewGrid } from '~CSVUploader/hooks/grid/useCSVPreviewGrid';

    // <!-- UTILITIES -->
    import { parse } from 'date-fns';

    // <!-- TYPES -->

    /** @typedef {import('@/models/v1/mappings/MappingProfile').MappingProfileResource} MappingProfileResource */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectProfileModal',
        components: {
            Panel,
            LoadingWrapper,
            FormSection,
            ModalButton,
            Switch,
            AgGridVue,
            PencilAltIcon,
            SearchIcon,
            DebugFrame,
            BasicAccordion,
        },
        props: {
            form: {
                /** @type {Vue.PropType<UploadFormConfig<any, any>>} */
                type: Object,
                required: true,
            },
            selector: {
                /** @type {Vue.PropType<ReturnType<useSelectProfileForm>>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            // ==== PROPS ====

            /** @type {Vue.Ref<UploadFormConfig<any, any>>} */
            const form = /** @type {any} */ (toRef(props, 'form'));

            /** @type {Vue.Ref<ReturnType<useSelectProfileForm>>} */
            const selector = /** @type {any} */ (toRef(props, 'selector'));

            const { store, cache } = form.value;

            // ==== COMPOSABLES ====
            /**
             * Get the component isMounted tracker
             * for use with the loading components.
             */
            const isMounted = useMounted();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            const { dirtyData } = selector.value.state;

            const {
                isOpen,
                isFetching,
                isFetchingIndex,
                isFetchingPreview,
                isFetchingProfile,
                isEditing,
                isViewing,
                isNewPresetSelected,
                isHeadersIncluded,
            } = selector.value.status;

            const {
                targetRecord,
                targetFilename,
                targetLocationPath,
                targetLocationDisplayName,
                targetContents,
                presetIndexOptions,
            } = selector.value.properties;

            const {
                closeModal,
                startEdit,
                cancelEdit,
                saveProfile,
                toggleHeaders,
                getTimeZoneOptions,
                getFieldIndexOptions,
                getFieldFormatOptions,
            } = selector.value.methods;

            const { onPresetChanged, onFieldChanged } = selector.value.handlers;

            const enabled = ref(true);

            // ==== COMPUTED PROPERTIES ====
            /** @type {Vue.ComputedRef<Map<String, UploadRecord>>} */
            const records = computed(() => {
                const _ = mountKey.value;
                return store.api.state.uploader.data.records;
            });

            /** @type {Vue.ComputedRef<Map<Number, MappingProfileResource>>} */
            const profileIndex = computed(() => {
                const _dep1 = mountKey.value;
                return store.api.state.cache.profiles.index;
            });

            const debugSaveProfile = async () => {
                const profile = [...profileIndex.value.values()][0];
                console.log(`DEBUG: Saving first profile in index to record.`);
                console.dir(profile);

                const newProfile = new MappingProfile({
                    resource: profile,
                }).toResource();
                newProfile.id = -1;

                // Save the profile.
                const record = targetRecord.value;
                await store.api.dispatch(
                    `uploader/data/selectRecordMappingProfile`,
                    {
                        filename: record.filename,
                        profile: newProfile,
                    }
                );
                closeModal();
            };

            const getOffsetValue = ({ offset, headers }) => {
                // Parse string value and switch to a 0-based index.
                let index = parseInt(String(offset), 10);
                index = !isNaN(index) && index > 0 ? index - 1 : 0;
                return index + (headers ? 1 : 0);
            };

            // Get the file grid module.
            const grid = useCSVPreviewGrid(context, {
                record: targetRecord,
            });

            /** Configure the grid module. */
            grid.useDefaultColDef({
                //flex: 1,
                minWidth: 50,
                //width: 100,
                resizable: true,
                sortable: true,
                filter: false,
                suppressSizeToFit: true,
            });
            watch(
                [
                    () => dirtyData.value.settings?.headers,
                    () => dirtyData.value.settings?.offset,
                ],
                ([useHeaders, useOffset]) => {
                    if (targetRecord.value) {
                        const offset = getOffsetValue({
                            offset: useOffset,
                            // Always set to false, useContents will do the math itself.
                            headers: false,
                        });

                        grid.useContents(
                            targetContents.value,
                            useHeaders,
                            offset
                        );
                    }
                },
                { immediate: true }
            );

            // ==== VALIDATION ====

            const dateFormatValidation = ({ value }) => {
                const { index } = dirtyData.value.rules[0];

                if (index === null) {
                    return true;
                }

                const formatString = {
                    iso: 'yyyy-MM-dd',
                    us: 'MM/dd/yyyy',
                    uni: 'yyyy/MM/dd',
                    eu: 'dd/MM/yyyy',
                    mon: 'dd/MMM/yyyy',
                    mon2y: 'dd/MMM/yy',
                    monxls: 'dd-MMM-yyyy',
                    monxls2y: 'dd-MMM-yy',
                    us2y: 'MM/dd/yy',
                    eu2y: 'dd/MM/yy',
                    aus: 'dd/MM/yyyy',
                }[value];

                // Check if any data at this index contains an unparseable date.
                return !targetContents.value.contents
                    .slice(getOffsetValue(dirtyData.value.settings))
                    .some(({ data }) => {
                        if (
                            !('length' in data) ||
                            data.length <= index ||
                            !data[index]
                        ) {
                            return false;
                        }

                        // Select the column value.
                        const columnValue = data[index];

                        // Evaluate some state metadata.
                        const flags = {
                            sharedTimeColumn:
                                dirtyData.value.rules[1].index === index,
                            containsWS: columnValue.includes(' '),
                            containsT: columnValue.includes('T'),
                        };

                        // Choose delimiter.
                        const delimiter = flags.containsT ? 'T' : ' ';

                        // If date and time share a column, parse the value before the first delimiter.
                        const dateString = flags.sharedTimeColumn
                            ? columnValue.split(delimiter)[0]
                            : columnValue;

                        try {
                            // Attempt to parse the result.
                            const result = parse(
                                dateString,
                                formatString,
                                new Date()
                            );
                            // Push this result.
                            return isNaN(result.getTime());
                        } catch {
                            // This case was not parseable.
                            return true;
                        }
                    });
            };

            const timeFormatValidation = ({ value }) => {
                const { index } = dirtyData.value.rules[1];

                if (index === null) {
                    return true;
                }

                const formatString = {
                    std: 'h:mm:ss aa',
                    std24nos: 'H:mm',
                    stdnos: 'h:mm aa',
                    std24: 'H:mm:ss',
                    std24wms: 'H:mm:ss.t',
                    std24wsc: 'H:mm;ss',
                    iso8601: 'HH:mm:ssxxx',
                    iso8601wms: 'HH:mm:ss.tXXX',
                    iso8601zulu: 'HH:mm:ssXXX',
                }[value];

                // Check if any data at this index contains an unparseable time.
                return !targetContents.value.contents
                    .slice(getOffsetValue(dirtyData.value.settings))
                    .some(({ data }) => {
                        if (
                            !('length' in data) ||
                            data.length <= index ||
                            !data[index]
                        ) {
                            return false;
                        }

                        // Select the column value.
                        const columnValue = data[index];

                        // Evaluate some state metadata.
                        const flags = {
                            sharedDateColumn:
                                dirtyData.value.rules[0].index === index,
                            containsWS: columnValue.includes(' '),
                            containsT: columnValue.includes('T'),
                            // containsZ: columnValue.includes('Z'),
                        };

                        // Choose delimiter.
                        const delimiter = flags.containsT ? 'T' : ' ';

                        // If date and time share a column, parse the value after the first delimiter.
                        let dateString = flags.sharedDateColumn
                            ? columnValue.substring(
                                  columnValue.indexOf(delimiter) + 1
                              )
                            : columnValue;

                        // // Attempt to remove the 'Z' character if one is present.
                        // dateString = flags.containsZ
                        //     ? dateString.replace('Z', '')
                        //     : dateString;

                        try {
                            const result = parse(
                                dateString,
                                formatString,
                                new Date()
                            );

                            return isNaN(result.getTime());
                        } catch {
                            return true;
                        }
                    });
            };

            const decimalValidation = ({ value }) => {
                // Check "temp" or "rh" rule(s), if set.
                return !dirtyData.value.rules
                    .filter(
                        (r) =>
                            r.index !== null &&
                            ['temp', 'rh'].includes(r.target)
                    )
                    .some(({ index }) => {
                        // Check if any data at this index contains the
                        // decimal separator that was not selected.
                        return targetContents.value.contents
                            .slice(getOffsetValue(dirtyData.value.settings))
                            .some(({ data }) => {
                                return (
                                    'length' in data &&
                                    data.length > index &&
                                    data[index].includes(
                                        value === '.' ? ',' : '.'
                                    )
                                );
                            });
                    });
            };

            const dateFormatInput = ref(null);
            const timeFormatInput = ref(null);
            const decimalInput = ref(null);

            const validateDateTimeInput = () => {
                // Re-run validation on Date Format setting.
                if (dirtyData.value.rules[0].format !== null) {
                    dateFormatInput.value.node.input(
                        dirtyData.value.rules[0].format
                    );
                }

                // Re-run validation on Time Format setting.
                if (dirtyData.value.rules[1].format !== null) {
                    timeFormatInput.value.node.input(
                        dirtyData.value.rules[1].format
                    );
                }
            };

            const validateDecimalInput = () => {
                // Re-run validation on Decimal Separator setting.
                decimalInput.value.node.input(dirtyData.value.settings.decimal);
            };

            const onDateTimeFieldChanged = (value, node) => {
                // Call general field changed handler.
                onFieldChanged(value, node);

                // Re-run validation that uses the date/time index values.
                validateDateTimeInput();
            };

            const onDecimalFieldChanged = (value, node) => {
                // Call general field changed handler.
                onFieldChanged(value, node);

                // Re-run validation that uses the temp/rh index values.
                validateDecimalInput();
            };

            const onOffsetFieldChanged = (value, node) => {
                // Call general field changed handler.
                onFieldChanged(value, node);

                // Re-run validation that uses the offset value.
                validateDateTimeInput();
                validateDecimalInput();
            };

            // ==== LIFECYCLE (onMounted) ====

            subscribeWatchedActions(store.api.store, [`*`]);
            subscribeWatchedMutations(store.api.store, [`*`]);

            // ==== LIFECYCLE (onUnmounted) ====

            onUnmounted(() => {
                isMounted.value = false;
                resetMountKey();
            });

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const _ = mountKey.value;
                    /** @type {Map<String, UploadRecord>} */
                    const records = store.api.state.uploader.data.records;
                    const target = selector.value.state.target.value;
                    const record = target != '' ? records.get(target) : null;

                    // Prepare data.
                    const data = [
                        DebugObject.create(
                            `Show Profile Modal`,
                            selector.value.state.open.value
                        ),
                        DebugObject.create(
                            `Clean Profile State`,
                            selector.value.state.cleanData.value
                        ),
                        DebugObject.create(
                            `Dirty Profile State`,
                            dirtyData.value
                        ),
                        DebugObject.create(`Record`, record),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({
                        startHidden: true,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            // EXPOSE
            return {
                // REACTIVITY
                isMounted,
                mountKey: getNamespacedMountKey,

                enabled,

                // PROPS
                targetRecord,
                targetFilename,
                targetLocationPath,
                targetLocationDisplayName,
                presetIndexOptions,
                dirtyData,
                profileIndex,
                grid,

                // METHODS
                closeModal,
                startEdit,
                cancelEdit,
                saveProfile,
                toggleHeaders,
                getTimeZoneOptions,
                getFieldIndexOptions,
                getFieldFormatOptions,

                // HANDLERS
                onPresetChanged,
                onFieldChanged,
                onDateTimeFieldChanged,
                onDecimalFieldChanged,
                onOffsetFieldChanged,

                // DEBUG
                frame,
                debugSaveProfile,

                // CONDITIONALS
                isOpen,
                isFetching,
                isFetchingIndex,
                isFetchingPreview,
                isFetchingProfile,
                isEditing,
                isViewing,
                isNewPresetSelected,
                isHeadersIncluded,

                // VALIDATION
                dateFormatInput,
                dateFormatValidation,
                timeFormatInput,
                timeFormatValidation,
                decimalInput,
                decimalValidation,
            };
        },
    });
</script>

<template>
    <div
        class="mt-6 flex justify-start align-center hover:cursor-pointer"
        @click="toggleAccordionExpanded"
        v-show="showExpansionFraming()"
    >
        <ChevronDownIcon
            v-if="accordionExpanded"
            v-show="showExpansionFraming()"
            class="h-6 w-6 inline"
            aria-hidden="true"
        />
        <ChevronRightIcon
            v-else
            v-show="showExpansionFraming()"
            class="h-6 w-6 inline"
            aria-hidden="true"
        />
        <SectionHeader v-show="showExpansionFraming()">
            {{ title }}
        </SectionHeader>
    </div>
    <div
        v-show="accordionExpanded || !!forceExpanded"
        :class="forceExpanded ? '' : 'px-4 py-2'"
    >
        <slot></slot>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPONENTS -->
    import { ChevronRightIcon } from '@heroicons/vue/outline';
    import { ChevronDownIcon } from '@heroicons/vue/outline';
    import SectionHeader from '@/components/SectionHeader.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'BasicAccordion',
        props: {
            title: String,
            forceExpanded: Boolean,
        },
        components: {
            ChevronRightIcon,
            ChevronDownIcon,
            SectionHeader,
        },
        setup(props) {
            /* Data */
            let accordionExpanded = ref(false);
            let showExpansionFraming = () =>
                props.forceExpanded ? false : true;

            /* Functions */
            const toggleAccordionExpanded = () => {
                accordionExpanded.value = !accordionExpanded.value;
            };

            return {
                accordionExpanded,
                showExpansionFraming,
                toggleAccordionExpanded,
            };
        },
    });
</script>
